import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import PageHeader from '../../layouts/page-header/PageHeader';
import trainingImg from '../../assets/images/training/trainingAugust24.webp';
import List from '../../component/list';


function Training() {


    const listItems = [
        { id: 1, item: 'Quality Management System (ISO 9001:2015)', url: '/' },
        {
            id: 2,
            item: 'Asset Management System (ISO 55001:2018)',
            url: '/',
        },
        {
            id: 3,
            item: 'Environmental Management System (EMS 14001:2015)',
            url: '/',
        },
        { id: 4, item: 'Laboratory Management System (ISO 17025:2017)', url: '/' },
        { id: 5, item: 'Occupational Health and Safety Management System (ISO 45001:2018)', url: '/' },
        { id: 6, item: 'Anti-bribery Management (ISO 37001:2016)', url: '/' },
        { id: 7, item: 'Food Safety Management System (ISO 22000:2018)', url: '/' },
        { id: 8, item: 'Risk Management System', url: '/' },
        { id: 9, item: 'Business Continuity Management (ISO 22301:2019)', url: '/' },
        { id: 10, item: 'Information Security Management (ISO 27001:2022)', url: '/' },
        { id: 11, item: 'Energy Management System (ISO 50001:2018)', url: '/' },
        { id: 12, item: 'Human Resource Management System (ISO 30414:)', url: '/' },
        { id: 13, item: 'Hazard Analysis Critical Control Points System (HACCP ZS 452:2014)', url: '/' },
        { id: 14, item: 'Integrated Management Systems', url: '/' },
        { id: 15, item: 'Good Agriculture Practices (GAP)', url: '/' },
        { id: 16, item: 'Business Continuity Management (ISO 22301:2019)', url: '/' },
        { id: 17, item: 'Quality Improvement Tools and Techniques', url: '/' },
        { id: 18, item: 'Prerequisites Programmes (PRPs) also referred to as (GMP) Good Manufacturing Practices', url: '/' },


    ];


    const listItemsOffers = [
        { id: 1, item: 'Mining and Extractive Industries.', url: '/' },
        {
            id: 2,
            item: 'Geological, Concentrates, Copper Cathodes including soil samples.',
            url: '/',
        },
        {
            id: 3,
            item: 'Energy and Petroleum.',
            url: '/',
        },
        { id: 4, item: 'Industrial Electrical, Solar Panels, Light Bulbs, Electric Cables and General Household Appliances', url: '/' },
        { id: 5, item: 'Construction Materials, Concrete and Concrete Blocks.', url: '/' },
        { id: 6, item: 'Textile, Fabric, Packaging materials, Personal Protective Equipment, Footwear and Paints.', url: '/' },
        { id: 7, item: 'Food and Non-Food Processors and Producers.', url: '/' },

    ];

    return (
        <>
            <div className="site-content">
                <PageHeader
                    title="Training"
                    description="Zambia Bureau of Standards (ZABS) is a leading provider of training in 
                                Management Systems to industry in Zambia and the region. "
                    breadCrumbItems={[
                        { label: 'Home', path: '/' },
                        { label: 'Pages', path: '#' },
                        { label: 'Training', path: '#', active: true },
                    ]}
                />
                <div className="content-wrapper">
                    <section className="section-ptb">
                        <Container>
                            <Row>
                                <Row className="justify-content-center mb-4 mt-md-5">
                                    <div className="col-xl-10">
                                        <h4 className="text-secondary">
                                            Zambia Bureau of Standards (ZABS) is a leading provider of training in
                                            Management Systems to industry in Zambia and the region. These training courses
                                            provided by ZABS put you and your organization on the fast track to success as it
                                            leverages the competitiveness of your products or services in both local and
                                            international markets.
                                        </h4>
                                        <p>
                                            ZABS has an exceptional team of trainers, competent to deliver world class
                                            training services to our esteemed clients. Our team of trainers comprise of ZABS
                                            members of staff who are qualified and technically competent professionals with
                                            vast experience in the delivery of training and consultancy in the area of courses
                                            offered.
                                        </p>
                                        <p className="mb-0">
                                            ZABS offers training to both individuals and organizations. ZABS conducts both open and in house training services;
                                        </p>

                                        <hr className="light-bg" />
                                        <h4 className="mb-0 text-secondary">
                                            In-house training
                                        </h4>
                                        <p className="mb-0">
                                            In-house trainings, upon, request, are conducted at the premises or any suitable
                                            venue organized by the organization needing the training.  This type of training is
                                            ideal for clients who want customized, cost effective and convenient training
                                            service delivery. The type of training is available to organizations with a minimum
                                            number of 5 and a maximum of 20 participants or delegates.
                                        </p>
                                        <hr className="light-bg" />
                                        <h4 className="mb-0 text-secondary">
                                            Open training
                                        </h4>
                                        <p className="mb-0">
                                            Open trainings are available to the general public on a particular set date and
                                            venue. The Bureau invites participants from the general public and corporate
                                            organizations to register for training in various courses. The dates and courses on
                                            offer for open trainings are outlined in the ZABS training calendar. The training
                                            calendar is available on the ZABS website. A minimum of 5 participants must
                                            register and pay for the training prior to its commencement.
                                        </p>
                                        <hr className="light-bg" />
                                        <h4 className="mb-0 text-secondary">
                                            Virtual training
                                        </h4>
                                        <p className="mb-0">
                                            ZABS also offers training through online platforms such as Zoom or Microsoft
                                            teams. Clients interested in convenient, easy to access training methods can opt to
                                            utilize the online training option.
                                        </p>


                                    </div>
                                </Row>

                            </Row>
                        </Container>
                    </section>
                    <section className="section-ptb bg-light">
                        <Container>
                            <Row className="pb-4 pb-md-5">
                                <Col lg={6} className="mb-4 mb-lg-0">
                                    <h4 className="mb-4 mb-md-5">Our laboratory operations are implemented in line with the requirements of the
                                        ISO standards. Our test methods are independently assessed and are accredited to
                                        the ISO/IEC 17025 Standard by the Southern African Development Community
                                        Accreditation Services (SADCAS). This means that the laboratory test results
                                        produced by ZABS are internationally recognized. </h4>
                                    <Row>
                                        <Col lg={10}>

                                            <img
                                                className="border-radius-5 w-100"
                                                src={trainingImg}
                                                alt=""
                                            />


                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6}>
                                    <h4>ZABS offers training in the following courses;</h4>
                                    <List icon="fas fa-check" data={listItems} />

                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <Row className="align-items-center pb-lg-5">
                        <Col>
                            {/* Optional content here */}
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default Training;