import React from 'react';
import './feature-step.scss';
import Button from '../button/button';

function FeatureStep (props) {
  // Destructure props for cleaner code
  const { tag: HeadingTag = 'h5', featureImg, title, stepNumber, description, buttons, extraClass = '' } = props;

  return (
    <div className={`feature-step ${extraClass}`}>
      {featureImg && (
        <div className="feature-step-img">
          <img className="img-fluid" src={featureImg} alt={title} />
        </div>
      )}
      <div className="feature-step-info">
        <span className="feature-step-number">{stepNumber}</span>
        <HeadingTag className="feature-step-title">{title}</HeadingTag>
      </div>
      {description && <p className="feature-step-content">{description}</p>}
      <hr className="light-bg" />

      {/* Render buttons if provided */}
      {buttons && buttons.map((button, index) => (
        <div key={index} className="button-container">
          {button.btnURL ? (
            <a
              href={button.btnURL}
              className={button.btnClass}
              download={button.btnURL.endsWith('.doc') ? true : undefined}
            >
              {button.btnText}
            </a>
          ) : (
            <Button
              btnClass={button.btnClass}
              btnText={button.btnText}
              btnURL={button.btnURL}
            />
          )}
        </div>
      ))}
    </div>
  );
}

function FeatureTimeline (props) {
  // Destructure props for consistency
  const { tag: HeadingTag = 'h5', stepNumber, title, description, extraClass = '' } = props;

  return (
    <div className={`feature-timeline ${extraClass}`}>
      <div className="feature-info-number">
        <span className="feature-timeline-number">{stepNumber}</span>
      </div>
      <div className="feature-info-content">
        <HeadingTag className="feature-timeline-title">{title}</HeadingTag>
        {description && <p className="feature-timeline-content">{description}</p>}
      </div>
    </div>
  );
}

export default FeatureStep;
export { FeatureTimeline };

