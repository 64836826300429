import React from 'react';

// Libraries
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

// Site Data
import PageHeaderZaqa from '../../layouts/page-header/PageHeaderZaqa';
import ZaqaSection from './zaqa-section';

function Zaqa(props) {
  return (
    <>
      <div className="site-content">
        <PageHeaderZaqa
          title="The Zambia Annual Quality Awards"
          description="Celebrating Excellence"
          breadCrumbItems={[
            { label: 'Home', path: '/' },
            { label: 'Pages', path: '#' },
            { label: 'Zambia Annual Quality Awards', path: '#', active: true },
          ]}
        />
        <div className="content-wrapper">
          <section className="section-ptb">
            <Container>
              <Row className="d-flex align-items-center">
                <ZaqaSection />
              </Row>

            </Container>





          </section>
        </div>
      </div>
    </>
  );
}

export default Zaqa;
