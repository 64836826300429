import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import ListLink from '../../component/list_link';


function Publications() {
    const publicationItems = [
        { id: 1, item: 'ZABS Annual Report 2023', url: '/documents/AR-2023.pdf' },
        { id: 2, item: 'ISO 9001:2015 Quality Management Guidelines', url: '#' },
        { id: 3, item: 'Environmental Management Systems Report 2022', url: '#' },
        { id: 4, item: 'Occupational Health and Safety Standards', url: '#' },
        { id: 5, item: 'Food Safety Management Systems (ISO 22000:2018)', url: '#' },
        { id: 6, item: 'ZABS Strategic Plan 2022-2026', url: '#' },
        { id: 7, item: 'Risk Management Guidelines', url: '#' },
        { id: 8, item: 'Energy Management Systems Implementation Guide', url: '#' },
        { id: 9, item: 'Business Continuity Management (ISO 22301:2019)', url: '#' },
    ];

    return (
        <>
            <div className="site-content">
                <PageHeader
                    title="Publications"
                    description="Download a variety of Zambia Bureau of Standards (ZABS) publications including reports, guidelines, and standards."
                    breadCrumbItems={[
                        { label: 'Home', path: '/' },
                        { label: 'Pages', path: '#' },
                        { label: 'Publications', path: '#', active: true },
                    ]}
                />
                <div className="content-wrapper">
                    <section className="section-ptb">
                        <Container>
                            <Row>
                                <Col xl={10} className="mx-auto">
                                    <h4 className="text-secondary">
                                        Explore our comprehensive collection of publications ranging from annual reports to detailed guides on various standards and management systems. These documents are available for free download to help you understand and implement ZABS standards.
                                    </h4>
                                    <p className="mb-0">
                                        The Zambia Bureau of Standards (ZABS) is dedicated to ensuring that the public and private sectors have access to valuable resources for understanding and applying various standards across industries.
                                    </p>
                                    <hr className="light-bg" />
                                    <h4 className="mb-0 text-secondary pt-4 pb-4">
                                        Available Publications:
                                    </h4>

                                    <ListLink icon="fas fa-download" data={publicationItems} />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </div>
        </>
    );
}

export default Publications;