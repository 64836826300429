import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import PageHeader from '../../layouts/page-header/PageHeader';
import VideoPopup from '../../component/video';
import videoImg from '../../assets/images/about/testing.webp';
import List from '../../component/list';


function TestingLaboratories() {


    const listItems = [
        { id: 1, item: 'Minerals laboratory.', url: '/' },
        {
            id: 2,
            item: 'Civils and Construction Material laboratory.',
            url: '/',
        },
        {
            id: 3,
            item: 'Solar laboratory.',
            url: '/',
        },
        { id: 4, item: 'Petroleum (Fuels) laboratory.', url: '/' },
        { id: 5, item: 'Paint Testing laboratory.', url: '/' },
        { id: 6, item: 'General Chemistry laboratory.', url: '/' },
        { id: 7, item: 'Microbiology laboratory.', url: '/' },
        { id: 8, item: 'Condom and Latex Gloves Testing laboratory.', url: '/' },
        { id: 9, item: 'Chromatography laboratory.', url: '/' },


    ];

    const listItemsOffers = [
        { id: 1, item: 'Mining and Extractive Industries.', url: '/' },
        {
            id: 2,
            item: 'Geological, Concentrates, Copper Cathodes including soil samples.',
            url: '/',
        },
        {
            id: 3,
            item: 'Energy and Petroleum.',
            url: '/',
        },
        { id: 4, item: 'Industrial Electrical, Solar Panels, Light Bulbs, Electric Cables and General Household Appliances', url: '/' },
        { id: 5, item: 'Construction Materials, Concrete and Concrete Blocks.', url: '/' },
        { id: 6, item: 'Textile, Fabric, Packaging materials, Personal Protective Equipment, Footwear and Paints.', url: '/' },
        { id: 7, item: 'Food and Non-Food Processors and Producers.', url: '/' },

    ];

    return (
        <>
            <div className="site-content">
                <PageHeader
                    title="Testing Laboratories"
                    description="Our affordable testing services cater to a diverse clientele. "
                    breadCrumbItems={[
                        { label: 'Home', path: '/' },
                        { label: 'Pages', path: '#' },
                        { label: 'Testing Laboratories', path: '#', active: true },
                    ]}
                />
                <div className="content-wrapper">
                    <section className="section-ptb">
                        <Container>
                            <Row>
                                <Row className="justify-content-center mb-4 mt-md-5">
                                    <div className="col-xl-10">
                                        <h4 className="text-secondary">
                                            Our primary function, in accordance with our mandate as stipulated under the
                                            Standards Act No. 4 of 2017, is to test raw, semi processed materials and finished
                                            products in order to assess their conformity to the minimum requirements of set
                                            standards and in accordance to good manufacturing practices.
                                        </h4>
                                        <p>
                                            ZABS Laboratories are equipped with modern equipment and are handled by
                                            highly competent and qualified staff. Our laboratories operations implement a
                                            quality management system based on International Standards.
                                        </p>

                                    </div>
                                </Row>

                            </Row>
                        </Container>
                    </section>
                    <section className="section-ptb bg-light">
                        <Container>
                            <Row className="pb-4 pb-md-5">
                                <Col lg={6} className="mb-4 mb-lg-0">
                                    <h4 className="mb-4 mb-md-5">Our laboratory operations are implemented in line with the requirements of the
                                        ISO standards. Our test methods are independently assessed and are accredited to
                                        the ISO/IEC 17025 Standard by the Southern African Development Community
                                        Accreditation Services (SADCAS). This means that the laboratory test results
                                        produced by ZABS are internationally recognized. </h4>
                                    <Row>
                                        <Col lg={10}>
                                            <VideoPopup
                                                videoURL="https://www.youtube.com/embed/SBxpuQrzOaM"
                                                videoImage={videoImg}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6}>
                                    <h4>ZABS Testing Laboratories</h4>
                                    <h5 className="text-secondary">
                                        We offer a wide variety of specialized laboratories, including:</h5>
                                    <List icon="fas fa-check" data={listItems} />
                                    <h5 className="text-secondary">
                                    ZABS offers affordable testing services for a wide range of industries, including:</h5>
                                    <List icon="fas fa-check" data={listItemsOffers} />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <Row className="align-items-center pb-lg-5">
                        <Col>
                            {/* Optional content here */}
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default TestingLaboratories;